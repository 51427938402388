import CustomButton from './CustonButton';
import React from 'react';
import { buttonType } from './CustomButtons.types';
import { customButtonStyles } from './CustomButtons.styles';

const PrimaryButton: React.FC<buttonType> = (props: buttonType) => {
	const classes = customButtonStyles();

	return (
		<CustomButton
			className={[classes.primary, props.className].join(' ')}
			{...props}
		/>
	);
};

export default PrimaryButton;
