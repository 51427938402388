import { Grid } from '@material-ui/core';
import React from 'react';
import { sectionStyles } from '../Section.styles';
import { sectionType } from '../Section.types';
import { theme } from '../../global/global.styles';

const CustomGridSection: React.FC<sectionType> = (props: sectionType) => {
	const classes = sectionStyles();

	return (
		<Grid
			container
			className={[classes.root, classes.customSectionStyle, props.className].join(' ')}
			style={{
				textAlign: props.centerize ? 'center' : 'inherit',
				backgroundColor: props.primary ? theme.palette.primary.main : '',
				...props.style,
			}}
		>
			{props.children}
		</Grid>
	);
};

export default CustomGridSection;
