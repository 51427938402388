import CustomButton from './CustonButton';
import { Grid } from '@material-ui/core';
import React from 'react';
import { customButtonStyles } from './CustomButtons.styles';
import { gridButtonType } from './CustomButtons.types';

const GridButton: React.FC<gridButtonType> = (props: gridButtonType) => {
	const classes = customButtonStyles();

	return (
		<Grid item xs={props.gridWidth}>
			<CustomButton
				className={[classes.grid, props.className].join(' ')}
				secondary
				{...props}
			/>
		</Grid>
	);
};

export default GridButton;
