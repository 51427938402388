import CustomList from '../List/CustomList';
import CustomTypography from './CustonTypography';
import React from 'react';
import { globalStyles } from '../global/global.styles';
import { paragraphType } from './Typograhy.types';

const Paragraph: React.FC<paragraphType> = (props: paragraphType) => {
	const renderBody = (): any => {
		if (typeof props.body === 'object') {
			return props.body.map((bodyelement: any, index: number) =>
				<CustomTypography
					key={index}
					variant={'body1'}
					style={{
						...props.style,
					}}
					text={bodyelement}
					{...props}
				/>
			);
		} else {
			return <CustomTypography
				variant={'body1'}
				style={{
					...props.style,
				}}
				text={props.body}
				{...props}
			/>;
		}

	};

	const renderList = (): JSX.Element | null =>
		props.list ? <CustomList contentList={props.list} animate /> : null;

	return (
		<React.Fragment>
			<CustomTypography
				variant={globalStyles.typography.subTitle.size}
				style={{
					marginTop: '5%',
					...props.style,
				}}
				text={props.title}
				bold
				{...props}
			/>
			{renderBody()}
			{renderList()}
		</React.Fragment>
	);
};

export default Paragraph;
