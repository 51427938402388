import React, { useRef } from 'react';

import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import ContactForm from '../../components/ContactForm/ContactForm';
import { Grid } from '@material-ui/core';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Section from '../../components/Sections/Section/Section';
import Subtitle from '../../components/Typography/Subtitle';
import Tilbyr from './Tilbyr';
import Title from '../../components/Typography/Title';
import TripleArrows from '../../components/Arrows/TripleArrows';
import { appContent } from '../../service/localization/application.i18n';
import { homeStyles } from './Home.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
	useTranslation();
	const classes = homeStyles();
	const content = appContent.Home.content.no;
	const tilbyrRef = useRef<any>(null);
	const history = useHistory();

	const scrollDown = React.useCallback(() => {
		if (tilbyrRef.current !== null) {
			try {
				window.scrollBy({
					left: 0,
					top: tilbyrRef.current.getBoundingClientRect().y - 65,
					behavior: 'smooth'
				});
			} catch (err) {
				console.error(err);
			}
		}
	}, [tilbyrRef]);

	return (
		<Grid container className={classes.root}>
			<div className={classes.backgroundImage}>
				<div className={classes.grey}>
					<Grid item xs={12} className={classes.header}>
						<Title text={ti18n(appContent.Home, content.home.title)} main bold />
						<div className={classes.subHeader}>
							<Subtitle text={ti18n(appContent.Home, content.home.subTitle1)} />
							<Subtitle text={ti18n(appContent.Home, content.home.subTitle2)} />
						</div>
						<PrimaryButton
							text={ti18n(appContent.Home, content.home.applyForHelpButton)}
							onClick={(): void => history.push(APP_ROUTES.FRI_RETTSHJELP)}
							animate
						/>
					</Grid>
				</div>
			</div>
			<Grid item xs={12} className={classes.scrollArrows}>
				<TripleArrows onClick={scrollDown} />
			</Grid>
			<Grid item xs={12}>
				<Tilbyr ref={tilbyrRef} />
			</Grid>
			<Section className={classes.contactForm}>
				<ContactForm pathName={'Hjem'} />
			</Section>
		</Grid>

	);
};

export default Home;
