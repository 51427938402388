import React from 'react';
import { Typography } from '@material-ui/core';
import { customTypographyType } from './Typograhy.types';

const CustomTypography: React.FC<customTypographyType> = (props: customTypographyType) => {

	return (
		<Typography
			variant={props.variant}
			style={{
				color: props.color,
				fontWeight: props.bold ? 'bold' : undefined,
				...props.style,
			}}
			className={[props.className].join(' ')}
			onClick={props.onClick}
		>
			{props.text}
		</Typography>
	);
};

export default CustomTypography;
