import { Button } from '@material-ui/core';
import React from 'react';
import { buttonType } from './CustomButtons.types';
import { customButtonStyles } from './CustomButtons.styles';

const CustomButton: React.FC<buttonType> = (props: buttonType) => {

	const classes = customButtonStyles({ animate: props.animate });

	return (
		<Button
			variant={'contained'}
			color={props.secondary ? 'secondary' : 'primary'}
			aria-label={props.text}
			className={[classes.general, props.className].join(' ')}
			onClick={props.onClick}
			style={props.style}
			disabled={props.disable}
			href={props.href}
		>
			{props.icon}
			{props.text}
		</Button>
	);
};

export default CustomButton;
