import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { arrowsStyles } from './Arrows.styles';

interface arrowType {
	opacity: string;
}

const Arrow: React.FC<arrowType> = (props: arrowType) => {
	const classes = arrowsStyles();

	return (
		<div className={classes.arrow}>
			<ExpandMoreIcon style={{ opacity: props.opacity }} />
		</div>
	);
};

export default Arrow;
