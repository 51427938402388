import { APP_ROUTES } from './AppRouter.constants';
import Arbeidsrett from '../../pages/Arbeidsrett/Arbeidsrett';
import Bedrifter from '../../pages/Bedrifter/Bedrifter';
import Eiendomsrett from '../../pages/Eiendomsrett/Eiendomsrett';
import Error404 from '../../pages/Error/Error404';
import Erstatningssaker from '../../pages/Erstatningssaker/Erstatningssaker';
import FamileArvSkifte from '../../pages/FamileArvSkifte/FamileArvSkifte';
import Forbruker from '../../pages/Forbruker/Forbruker';
import FriRettshjelp from '../../pages/FriRettshjelp/FriRettshjelp';
import Home from '../../pages/Home/Home';
import OmOss from '../../pages/OmOss/OmOss';
import Personvern from '../../pages/Personvern/Personvern';
import { RoutesType } from './Routes.types';

export const appRoutes: RoutesType[] = [
	{
		path: APP_ROUTES.ROOT,
		component: Home,
		exact: true,
	},
	{
		path: APP_ROUTES.FRI_RETTSHJELP,
		component: FriRettshjelp,
		exact: true,
	},
	{
		path: APP_ROUTES.OM_OSS,
		component: OmOss,
		exact: true,
	},
	{
		path: APP_ROUTES.ARBEIDSRETT,
		component: Arbeidsrett,
		exact: true,
	},
	{
		path: APP_ROUTES.BEDRIFTER,
		component: Bedrifter,
		exact: true,
	},
	{
		path: APP_ROUTES.EIENDOMSRETT,
		component: Eiendomsrett,
		exact: true,
	},
	{
		path: APP_ROUTES.ERSTATNINGSSAKER,
		component: Erstatningssaker,
		exact: true,
	},
	{
		path: APP_ROUTES.FAMILIE_ARV_OG_SKIFTE,
		component: FamileArvSkifte,
		exact: true,
	},
	{
		path: APP_ROUTES.FORBRUKER,
		component: Forbruker,
		exact: true,
	},
	{
		path: APP_ROUTES.ERROR_404,
		component: Error404,
	},
	{
		path: APP_ROUTES.PERSONVERN,
		component: Personvern,
	},
];
