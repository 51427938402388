import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const footerStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		marginTop: '5%',
		marginBottom: '0px',
	},
	footerGridMobile: {
		textAlign: 'inherit',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	logo: {
		height: '140px',
		width: '200px',
		cursor: 'pointer',
		'&:hover': {
			filter: 'brightness(50%)',
		},
	},
	footerText: {
		color: theme.palette.common.white,
	},
	link: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	rettsOmrader: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	withIcon: {
		display: 'inline-flex',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	icon: {
		marginRight: '5px',
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
}));
