import CustomHeaderSection from '../../components/Sections/CustomHeaderSection/CustomHeaderSection';
import { Grid } from '@material-ui/core';
import React from 'react';
import Section from '../../components/Sections/Section/Section';
import Subtitle from '../../components/Typography/Subtitle';
import Title from '../../components/Typography/Title';
// import { personvernStyles } from './Personvern.styles';
import { appContent } from '../../service/localization/application.i18n';
import { theme } from '../../components/global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useTranslation } from 'react-i18next';

const Personvern: React.FC = () => {
	useTranslation();
	// const classes = personvernStyles();
	const content = appContent.Personvern.content.no;

	return (
		<React.Fragment>
			<CustomHeaderSection>
				<Title
					text={ti18n(appContent.Personvern, content.title)}
					color={theme.palette.common.white}
					bold
				/>
				<Subtitle
					text={ti18n(appContent.Personvern, content.subTitle)}
					color={theme.palette.common.white}
				/>
			</CustomHeaderSection>
			<Section>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						dgdfgd
					</Grid>
				</Grid>
			</Section>
		</React.Fragment>
	);
};

export default Personvern;
