import React, { forwardRef } from 'react';

import CustomGridSection from '../../components/Sections/CustomGridSection/CustomGridSection';
import GavelIcon from '@material-ui/icons/Gavel';
import { Grid } from '@material-ui/core';
import GridButton from '../../components/Buttons/GridButton';
import Subtitle from '../../components/Typography/Subtitle';
import Title from '../../components/Typography/Title';
import { appContent } from '../../service/localization/application.i18n';
import { menuListType } from '../../components/NavBar/NavBarMenu.types';
import { subMenuList } from '../../components/NavBar/NavBarMenuItems';
import { theme } from '../../components/global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { tilbyrStyles } from './Tilbyr.styles';
import { useColumnDivider } from '../../service/service/useColumnDivider';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Tilbyr = forwardRef<any>((props, ref) => {
	useTranslation();
	const classes = tilbyrStyles();
	const history = useHistory();
	const content = appContent.Home.content.no;

	return (
		<div ref={ref}>
			<CustomGridSection className={classes.root} primary>
				<GavelIcon className={classes.icon} />
				<Title
					color={theme.palette.common.white}
					text={ti18n(appContent.Home, content.tilbyr.title)}
					bold
				/>
				<Subtitle
					color={theme.palette.common.white}
					text={ti18n(appContent.Home, content.tilbyr.subTitle)}
				/>
				<Grid
					container
					spacing={3}
					justifyContent={'center'}
					className={classes.gridButtons}
				>
					{
						subMenuList.map((menuItem: menuListType, index: number) =>
							<GridButton
								key={index}
								gridWidth={useColumnDivider(4, 12)}
								text={ti18n(appContent.NavBar, menuItem.title)}
								onClick={(): void => history.push(menuItem.link)}
								animate
							/>
						)
					}
				</Grid>
			</CustomGridSection>
		</div>
	);
});

Tilbyr.displayName = 'Tilbyr';

export default Tilbyr;
