import ArbeidsrettContent from '../../pages/Arbeidsrett/Content';
import BedrifterContent from '../../pages/Bedrifter/Content';
import ContactFormContent from '../../components/ContactForm/Content';
import EiendomsrettContent from '../../pages/Eiendomsrett/Content';
import ErrorContent from '../../pages/Error/Content';
import ErstatningssakerContent from '../../pages/Erstatningssaker/Content';
import FamileArvSkifteContent from '../../pages/FamileArvSkifte/Content';
import FooterContent from '../../components/Footer/Content';
import ForbrukerContent from '../../pages/Forbruker/Content';
import FriRettshjelpContent from '../../pages/FriRettshjelp/Content';
import HomeContent from '../../pages/Home/Content';
import NavBarContent from '../../components/NavBar/Content/index';
import OmOssContent from '../../pages/OmOss/Content';
import PersonvernContent from '../../pages/Personvern/Content';

export const appContent = {
	Home: {
		name: 'Home',
		content: HomeContent,
	},
	FriRettshjelp: {
		name: 'FriRettshjelp',
		content: FriRettshjelpContent,
	},
	OmOss: {
		name: 'OmOss',
		content: OmOssContent,
	},
	Arbeidsrett: {
		name: 'Arbeidsrett',
		content: ArbeidsrettContent,
	},
	Bedrifter: {
		name: 'Bedrifter',
		content: BedrifterContent,
	},
	Eiendomsrett: {
		name: 'Eiendomsrett',
		content: EiendomsrettContent,
	},
	Erstatningssaker: {
		name: 'Erstatningssaker',
		content: ErstatningssakerContent,
	},
	FamileArvSkifte: {
		name: 'FamileArvSkifte',
		content: FamileArvSkifteContent,
	},
	Forbruker: {
		name: 'Forbruker',
		content: ForbrukerContent,
	},
	Error: {
		name: 'Error',
		content: ErrorContent,
	},
	Personvern: {
		name: 'Personvern',
		content: PersonvernContent,
	},
	ContactForm: {
		name: 'ContactForm',
		content: ContactFormContent,
	},
	Footer: {
		name: 'Footer',
		content: FooterContent,
	},
	NavBar: {
		name: 'NavBar',
		content: NavBarContent,
	},
};
