import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { customListStyles } from './CustomList.styles';
import { customListType } from './CustomList.types';

const CustomList: React.FC<customListType> = (props: customListType) => {

	const classes = customListStyles({ animate: props.animate });

	return (
		<List className={classes.list}>
			{
				props.contentList.map((content, index: number) =>
					<ListItem key={index} className={classes.listElement}>
						<ListItemIcon className={classes.listContent}>
							<ChevronRightIcon />
						</ListItemIcon>
						<ListItemText className={classes.listContent} primary={content} />
					</ListItem>
				)
			}
		</List>
	);
};

export default CustomList;
