import { Variant } from '@material-ui/core/styles/createTypography';
import { createTheme } from '@material-ui/core';

export const globalMainTitle: Variant = 'h3';
export const globalTitle: Variant = 'h4';
export const globalSubTitle: Variant = 'h6';

export const globalStyles = {
	appMargin: '15%',
	appMarginMobile: '5%',
	typography: {
		main: {
			size: globalMainTitle,
			margin25px: '25px',
		},
		title: {
			size: globalTitle,
			margin25px: '25px',
		},
		subTitle: {
			size: globalSubTitle,
			margin12px: '12px',
		},
	},
	colors: {
		primary: {
			light: '#ffd59e',
			main: '#d8ad75',
			dark: '#be8b1e',
		},
	},
	borderRadius: {
		small10px: '10px',
		large20px: '20px',
	},
};
const defaultTheme = createTheme();

export const theme = createTheme({
	palette: {
		primary: {
			light: globalStyles.colors.primary.light,
			main: globalStyles.colors.primary.main,
			dark: globalStyles.colors.primary.dark,
			contrastText: defaultTheme.palette.common.white,
		},
		secondary: {
			light: defaultTheme.palette.common.white,
			main: defaultTheme.palette.common.white,
			dark: defaultTheme.palette.common.white,
			contrastText: globalStyles.colors.primary.dark,
		},
	},
});
