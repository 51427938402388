import { useCallback, useEffect, useState } from 'react';

import { theme } from '../../components/global/global.styles';
import { useMediaQuery } from '@material-ui/core';
import { useScreenResizeListener } from './useScreenResizeListener';

export const useColumnDivider = (desktop: any, mobile: any): any => {
	const [cols, setCols] = useState(desktop);
	const xsMobileWidthSize = useMediaQuery(theme.breakpoints.down('xs'));
	const getCols = useCallback((): any => {
		if (xsMobileWidthSize) {
			return mobile;
		} else {
			return desktop;
		}
	}, [desktop, mobile, xsMobileWidthSize]);

	const numberOfCols = useScreenResizeListener(getCols);

	useEffect(() => {
		setCols(numberOfCols);
	}, [numberOfCols]);

	useEffect(() => {
		setCols(getCols);
	}, [getCols]);

	return cols;
};
