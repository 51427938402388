import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import CustomHeaderSection from '../../components/Sections/CustomHeaderSection/CustomHeaderSection';
import React from 'react';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import Subtitle from '../../components/Typography/Subtitle';
import Title from '../../components/Typography/Title';
import { appContent } from '../../service/localization/application.i18n';
import { theme } from '../../components/global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error404: React.FC = () => {
	useTranslation();
	const content = appContent.Error.content.no;
	const history = useHistory();

	return (
		<React.Fragment>
			<CustomHeaderSection>
				<Title
					nowrap
					text={ti18n(appContent.Error, content.pageCouldNotBeFound)}
					color={theme.palette.common.white}
					bold
				/>

				<Subtitle
					text={content[404]}
					color={theme.palette.common.white}
				/>

				<SecondaryButton
					text={ti18n(appContent.Error, content.goBackToFrontPage)}
					onClick={(): void => history.push(APP_ROUTES.ROOT)}
					animate
				/>
			</CustomHeaderSection>
		</React.Fragment>
	);
};

export default Error404;
