import { MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { appContent } from '../../service/localization/application.i18n';
import { navBarItemType } from './NavBarMenu.types';
import { navBarStyles } from './NavBar.styles';
import { ti18n } from '../../service/localization/ti18n';

const CustomNavLink: React.FC<navBarItemType> = (props: navBarItemType) => {
	const classes = navBarStyles();

	return <NavLink to={props.link} className={classes.link}>
		<MenuItem role={'button'} onClick={props.onClick} className={[props.className, classes.menuItem].join(' ')}>
			{
				props.children ? props.children : ti18n(appContent.NavBar, props.title)
			}
		</MenuItem>
	</NavLink>;
};

export default CustomNavLink;
