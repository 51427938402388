import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import { appContent } from '../../service/localization/application.i18n';
import { menuListType } from './NavBarMenu.types';

const content = appContent.NavBar.content.no;

export const subMenuList: menuListType[] = [
	{
		title: content.navBarLinks.arbeidsrett,
		link: APP_ROUTES.ARBEIDSRETT,
		items: [],
	},
	{
		title: content.navBarLinks.bedrifter,
		link: APP_ROUTES.BEDRIFTER,
		items: [],
	},
	{
		title: content.navBarLinks.eiendomsrett,
		link: APP_ROUTES.EIENDOMSRETT,
		items: [],
	},
	{
		title: content.navBarLinks.erstatningssaker,
		link: APP_ROUTES.ERSTATNINGSSAKER,
		items: [],
	},
	{
		title: content.navBarLinks.famileArvSkifte,
		link: APP_ROUTES.FAMILIE_ARV_OG_SKIFTE,
		items: [],
	},
	{
		title: content.navBarLinks.forbruker,
		link: APP_ROUTES.FORBRUKER,
		items: [],
	},
];

export const menuList: menuListType[] = [
	{
		title: content.navBarLinks.friRettshjelp,
		link: APP_ROUTES.FRI_RETTSHJELP,
		items: [],
	},
	{
		title: content.navBarLinks.omOss,
		link: APP_ROUTES.OM_OSS,
		items: [],
	},
	{
		title: content.navBarLinks.fagområder,
		link: '',
		items: subMenuList,
	},
];
