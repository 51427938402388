import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../global/global.styles';

const animation = (props: any): "scale(1.1)" | null => {
	return props.animate ? 'scale(1.1)' : null;
};

export const customButtonStyles = makeStyles((theme: Theme) => createStyles({
	general: {
		textAlign: 'center',
		fontWeight: 'bold',
		borderRadius: globalStyles.borderRadius.small10px,
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.standard,
		}),
		'&:hover': {
			transform: (props): string | null => animation(props)
		},
	},
	primary: {
		height: '45px',
	},
	secondary: {
		height: '45px',
	},
	grid: {
		height: '105px',
		padding: '70px 0px',
		width: '100%',
		borderRadius: globalStyles.borderRadius.large20px,
		[theme.breakpoints.down('xs')]: {
			height: '50px',
			padding: '2% 0%',
		},
	},
}));
