import AppRouter from './service/routes/AppRouter';
import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import React from 'react';
import ScrollToTop from './service/service/ScrollToTop';

const App: React.FC = () => {
	return (
		<React.Fragment>
			<ScrollToTop />
			<NavBar />
			<AppRouter />
			<Footer />
		</React.Fragment>
	);
};

export default App;
