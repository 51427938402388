import ContactForm from '../../components/ContactForm/ContactForm';
import CustomHeaderSection from '../../components/Sections/CustomHeaderSection/CustomHeaderSection';
import { Grid } from '@material-ui/core';
import Paragraph from '../../components/Typography/Paragraph';
import React from 'react';
import Section from '../../components/Sections/Section/Section';
import Subtitle from '../../components/Typography/Subtitle';
import Title from '../../components/Typography/Title';
// import { famileArvSkifteStyles } from './FamileArvSkifte.styles';
import { appContent } from '../../service/localization/application.i18n';
import { theme } from '../../components/global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useColumnDivider } from '../../service/service/useColumnDivider';
import { useTranslation } from 'react-i18next';

const FamileArvSkifte: React.FC = () => {
	useTranslation();
	// const classes = famileArvSkifteStyles();
	const content = appContent.FamileArvSkifte.content.no;

	return (
		<React.Fragment>
			<CustomHeaderSection>
				<Title
					text={ti18n(appContent.FamileArvSkifte, content.title)}
					color={theme.palette.common.white}
					bold
				/>
				<Subtitle
					text={ti18n(appContent.FamileArvSkifte, content.subTitle)}
					color={theme.palette.common.white}
				/>
			</CustomHeaderSection>
			<Section>
				<Grid container spacing={3}>
					<Grid item xs={useColumnDivider(6, 12)}>
						<Paragraph
							title={ti18n(appContent.FamileArvSkifte, content.sakkyndigeErstatning.title)}
							body={ti18n(appContent.FamileArvSkifte, content.sakkyndigeErstatning.body)}
						/>
						<Paragraph
							title={ti18n(appContent.FamileArvSkifte, content.fagområder.title)}
							list={ti18n(appContent.FamileArvSkifte, content.fagområder.list)}
						/>
					</Grid>
					<Grid item xs={useColumnDivider(6, 12)}>
						<ContactForm pathName={ti18n(appContent.FamileArvSkifte, content.title)} />
					</Grid>
				</Grid>
			</Section>
		</React.Fragment>
	);
};

export default FamileArvSkifte;
