import { Theme, createStyles, makeStyles } from '@material-ui/core';

import Image from '../../static/images/Forside.jpg';

export const homeStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		textAlign: 'center',
	},
	header: {
		color: theme.palette.common.white,
		transform: 'scaleX(-1)',
	},
	backgroundImage: {
		backgroundImage: `url(${Image})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		transform: 'scaleX(-1)',
		width: '100%',
		height: 'auto',
	},
	grey: {
		width: '100%',
		height: 'auto',
		paddingTop: '125px',
		paddingBottom: '250px',
		background: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5))',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '50px',
			paddingBottom: '20px',
		},
	},
	subHeader: {
		marginBottom: '40px',
	},
	scrollArrows: {
		margin: '5% 0%',
	},
	contactForm: {
		width: '50%',
		margin: 'auto',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '5%',
		},
	},
}));
