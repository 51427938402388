import { useEffect, useState } from 'react';

export const useScreenResizeListener = (callback: any): any => {
	const [state, setstate] = useState<any>(callback);

	useEffect(() => {
		const handleResize = (): void => {
			setstate(callback);
		};

		window.addEventListener('resize', handleResize);

		return (): void => {
			return window.removeEventListener('resize', handleResize);
		};
	}, [callback]);

	return state;
};
