import React from 'react';
import { sectionStyles } from '../Section.styles';
import { sectionType } from '../Section.types';
import { theme } from '../../global/global.styles';

const CustomHeaderSection: React.FC<sectionType> = (props: sectionType) => {
	const classes = sectionStyles();

	return (
		<div
			className={[classes.root, classes.customSectionStyle, classes.customHeaderSectionStyle, props.className].join(' ')}
			style={{
				backgroundColor: theme.palette.primary.main,
				textAlign: props.centerize ? 'center' : 'inherit',
				...props.style
			}}
		>
			{props.children}
		</div>
	);
};

export default CustomHeaderSection;
