import { AppBar, Button, Toolbar } from '@material-ui/core';

import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import DesktopNavBar from './DesktopNavBar/DesktopNavBar';
import MobileNavBar from './MobileNavBar/MobileNavBar';
import React from 'react';
import Section from '../Sections/Section/Section';
import logo from '../../static/images/Viken.png';
import { navBarStyles } from './NavBar.styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavBar: React.FC = () => {
	useTranslation();
	const classes = navBarStyles();
	const history = useHistory();

	return (
		<Section className={classes.section}>
			<AppBar position={'relative'}>
				<Toolbar className={classes.root}>
					<Button
						style={{ width: '90px' }}
						onClick={(): void => history.push(APP_ROUTES.ROOT)}
						onKeyPress={(): void => history.push(APP_ROUTES.ROOT)}
					>
						<img src={logo} alt={'NavBar Logo'} className={classes.logo} />
					</Button>
					<MobileNavBar />
					<DesktopNavBar />
				</Toolbar>
			</AppBar>
		</Section>
	);
};

export default NavBar;
