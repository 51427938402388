export const APP_ROUTES = {
	ROOT: '/',
	FRI_RETTSHJELP: '/fri-rettshjelp',
	OM_OSS: '/om-oss',
	ARBEIDSRETT: '/fagomrader/arbeidsrett',
	BEDRIFTER: '/fagomrader/bedrifter',
	EIENDOMSRETT: '/fagomrader/eiendomsrett',
	ERSTATNINGSSAKER: '/fagomrader/erstatningssaker',
	FAMILIE_ARV_OG_SKIFTE: '/fagomrader/famile-arv-og-skifte',
	FORBRUKER: '/fagomrader/forbruker',
	ERROR_404: '/error/404',
	PERSONVERN: '/personvern',
};
