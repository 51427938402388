import { useEffect, useState } from 'react';

export const useWifiStatus = (): boolean => {
	const [wifiStatus, setWifiStatus] = useState<boolean>(navigator.onLine);

	useEffect(() => {
		const changeStatus = (): void => {
			setWifiStatus(navigator.onLine);
		};

		window.addEventListener('online', changeStatus);
		window.addEventListener('offline', changeStatus);

		return (): void => {
			window.removeEventListener('online', changeStatus);
			window.removeEventListener('offline', changeStatus);
		};
	}, []);

	return wifiStatus;
};
