import React from 'react';
import { sectionStyles } from '../Section.styles';
import { sectionType } from '../Section.types';

const Section: React.FC<sectionType> = (props: sectionType) => {
	const classes = sectionStyles();

	return (
		<div
			className={[classes.root, props.className].join(' ')}
			style={props.style}
		>
			{props.children}
		</div>
	);
};

export default Section;
