import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../global/global.styles';

export const arrowsStyles = makeStyles((theme: Theme) => createStyles({
	tripleArrows: {
		borderRadius: globalStyles.borderRadius.small10px,
		animation: '$bounce 2500ms ease',
		animationIterationCount: 'infinite',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	'@keyframes bounce': {
		'0%': {
			transform: 'translateY(25px)'
		},
		'50%': {
			transform: 'translateY(-25px)'
		},
		'100%': {
			transform: 'translateY(25px)'
		}
	},
	arrow: {
		margin: '-7px',
		width: '40px',
		height: '80%',
		color: theme.palette.primary.dark,
	},
}));
