import Arrow from './Arrow';
import { IconButton } from '@material-ui/core';
import React from 'react';
import { arrowsStyles } from './Arrows.styles';
import { tripleArrowsType } from './Arrows.types';

const TripleArrows: React.FC<tripleArrowsType> = (props: tripleArrowsType) => {
	const classes = arrowsStyles();

	return (
		<IconButton
			aria-label='scroll ned til tilbyr'
			className={[classes.tripleArrows, props.className].join(' ')}
			onClick={props.onClick}
		>
			<div>
				<Arrow opacity={'25%'} />
				<Arrow opacity={'50%'} />
				<Arrow opacity={'100%'} />
			</div>
		</IconButton>
	);
};

export default TripleArrows;
