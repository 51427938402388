import CustomTypography from './CustonTypography';
import React from 'react';
import { customTypographyType } from './Typograhy.types';
import { globalStyles } from '../global/global.styles';

const Title: React.FC<customTypographyType> = (props: customTypographyType) => {
	return (
		<CustomTypography
			variant={props.main ? globalStyles.typography.main.size : globalStyles.typography.title.size}
			className={[props.className].join(' ')}
			style={{
				marginBottom: props.main ? globalStyles.typography.main.margin25px : globalStyles.typography.title.margin25px,
				...props.style,
			}}
			{...props}

		/>
	);
};

export default Title;
