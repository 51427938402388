import { appContent } from './application.i18n';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

type Content<T> = { [key: string]: T };

const applicationContent: Content<any> = appContent;
const resources = {
	no: {},
	en: {},
	pl: {},
	ar: {},
};

const keys = [];

for (const key in applicationContent) {
	keys.push(key);

	Object.assign(resources.no, { [key]: applicationContent[key].content.no });
	Object.assign(resources.en, { [key]: applicationContent[key].content.en });
	Object.assign(resources.pl, { [key]: applicationContent[key].content.pl });
	Object.assign(resources.ar, { [key]: applicationContent[key].content.ar });
}

// passes i18n down to react-i18next
i18n.use(initReactI18next)
	.init({
		// fallbackLng: 'no',
		resources,
		lng: 'no',
		ns: keys,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
