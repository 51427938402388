import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../global/global.styles';

export const contactFormStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		padding: '5%',
		backgroundColor: theme.palette.primary.main,
		borderRadius: globalStyles.borderRadius.large20px,
		position: 'sticky',
		top: '10%',
		[theme.breakpoints.down('sm')]: {
			top: '22%',
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		position: 'absolute',
		borderRadius: 'inherit',
	},
	contact: {
		margin: '5px',
	},
	alert: {
		borderRadius: globalStyles.borderRadius.large20px
	},
	textField: {
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.standard,
		}),
		'&:hover': {
			transform: 'scale(1.1)',
		},
		'& label': {
			color: theme.palette.common.white,
		},
		'& label.Mui-focused': {
			color: theme.palette.common.white,
			fontWeight: 'bold',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: theme.palette.common.white,
				opacity: '50%',
			},
			'&:hover fieldset': {
				borderColor: theme.palette.common.white,
				opacity: '100%',
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.common.white,
			},
		},
	},
}));
