import { MenuItem, Popover } from '@material-ui/core';
import React, { useState } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomNavLink from '../CustomNavLink';
import LanguageDropDown from '../LanguageDropDown';
import { appContent } from '../../../service/localization/application.i18n';
import { menuList } from '../NavBarMenuItems';
import { menuListType } from '../NavBarMenu.types';
import { navBarStyles } from '../NavBar.styles';
import { ti18n } from '../../../service/localization/ti18n';

const DesktopNavBar: React.FC = () => {
	const classes = navBarStyles();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleAnchorClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleAnchorClose = (): void => {
		setAnchorEl(null);
	};

	const generateMenu = (item: menuListType): JSX.Element => {
		if (item.items.length > 0) {
			return <React.Fragment key={item.title}>
				<MenuItem role={'button'} onClick={handleAnchorClick} className={classes.menuItem}>
					{ti18n(appContent.NavBar, item.title)}
					<ArrowDropDownIcon />
				</MenuItem>
				<Popover
					id={'fagområder-menu'}
					anchorEl={anchorEl}
					anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
					open={Boolean(anchorEl)}
					onClose={handleAnchorClose}
				>
					{
						item.items.map((subMenuItem: menuListType) => generateMenu(subMenuItem))
					}
				</Popover>
			</React.Fragment>;
		} else {
			return <CustomNavLink {...item} key={item.link} onClick={handleAnchorClose}/>;
		}
	};

	return (
		<div className={classes.desktop}>
			{
				menuList.map((menuItem: menuListType) => generateMenu(menuItem))
			}
			<LanguageDropDown />
		</div>
	);
};

export default DesktopNavBar;
