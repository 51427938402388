import { Backdrop, CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';

import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
// import ReCAPTCHA from 'react-google-recaptcha';
import SecondaryButton from '../Buttons/SecondaryButton';
import Subtitle from '../Typography/Subtitle';
import { appContent } from '../../service/localization/application.i18n';
import { contactFormStyles } from './ContactForm.styles';
import { contactFormType } from './ContactForm.types';
import emailjs from 'emailjs-com';
import globalContent from '../global/global.content.json';
import { theme } from '../global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useWifiStatus } from '../../service/service/useWifiStatus';

// import React, { useEffect, useRef, useState } from 'react';
// import { onSubmitWithReCAPTCHA, resetReCAPTCHA } from '../../service/service/ReCaptcha';

const ContactForm: React.FC<contactFormType> = (props: contactFormType) => {
	const content = appContent.ContactForm.content.no;
	const classes = contactFormStyles({});

	const messageCharacterLimit = 500;
	const [backdrop, setBacdrop] = useState(false);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [message, setMessage] = useState('');
	const [messageCounter, setMessageCounter] = useState(500);

	const [showFormSentSuccess, setShowFormSentSuccess] = useState(false);
	const [showFormSentFailure, setShowFormSentFailure] = useState(false);
	const [showWifiFailure, setShowWifiFailure] = useState(false);

	// const recaptchaRef = useRef<any>();
	const wifiStatus = useWifiStatus();

	const [errors, setErrors] = useState<{
		nameError?: string,
		emailError?: string,
		phoneNumberError?: string,
		messageError?: string,
	}>({
		nameError: undefined,
		emailError: undefined,
		phoneNumberError: undefined,
		messageError: undefined,
	});

	const [disabled, setDisabled] = useState(true);

	const Alert = (alertProps: AlertProps): JSX.Element => {
		return <MuiAlert elevation={6} variant={'filled'} {...alertProps} />;
	};

	const nameValidation = (fieldValue: string): void => {
		setName(fieldValue);
		let error = undefined;

		if (/[^a-zA-ZæÆøØåÅ -]/.test(fieldValue)) {
			error = ti18n(appContent.ContactForm, content.inputFields.name.errorMsg.invalid);
		}
		if (fieldValue.trim().length < 2) {
			error = ti18n(appContent.ContactForm, content.inputFields.name.errorMsg.lengthRequirement);
		}
		if (fieldValue.trim() === '') {
			error = ti18n(appContent.ContactForm, content.inputFields.name.errorMsg.mandatory);
		}

		setErrors({
			...errors,
			nameError: error,
		});
	};

	const emailValidation = (fieldValue: string): void => {
		setEmail(fieldValue.trim());
		let error = undefined;

		if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(fieldValue)) {
			error = ti18n(appContent.ContactForm, content.inputFields.email.errorMsg.invalid);
		}
		if (fieldValue.trim() === '') {
			error = ti18n(appContent.ContactForm, content.inputFields.email.errorMsg.mandatory);
		}

		setErrors({
			...errors,
			emailError: error,
		});
	};

	const phoneNumberValidation = (fieldValue: string): void => {
		setPhoneNumber(fieldValue.trim());
		let error = undefined;

		if (!/^((\+|00) *[0-9]{2})*[ 0-9]{8,}/.test(fieldValue)) {
			error = ti18n(appContent.ContactForm, content.inputFields.phoneNumber.errorMsg.invalid);
		}
		if (fieldValue.trim() === '') {
			error = ti18n(appContent.ContactForm, content.inputFields.phoneNumber.errorMsg.mandatory);
		}
		if (fieldValue.trim().length < 8) {
			error = ti18n(appContent.ContactForm, content.inputFields.phoneNumber.errorMsg.lengthRequirement);
		}

		setErrors({
			...errors,
			phoneNumberError: error,
		});
	};

	const messageValidation = (fieldValue: string): void => {
		setMessage(fieldValue);
		let error = undefined;

		if (fieldValue.trim() === '') {
			error = ti18n(appContent.ContactForm, content.inputFields.message.errorMsg.mandatory);
		}

		setErrors({
			...errors,
			messageError: error,
		});
	};

	const sendForm = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
		event.preventDefault();
		await setBacdrop(true);

		// const validToken = await onSubmitWithReCAPTCHA(recaptchaRef);

		// if (validToken) {
		await emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, {
			fromName: name,
			fromEmail: email,
			fromPhone: phoneNumber,
			message: message,
			page: {
				title: props.pathName,
				url: window.location.href
			},
		}, process.env.REACT_APP_EMAILJS_USER)
			.then(({ status }: any) => {
				if (status === 200) {
					setShowFormSentSuccess(true);
				}
			}, (err: any) => {
				console.log('EMAILJS ERROR', err);
				setShowFormSentFailure(true);
			});
		// }

		// await resetReCAPTCHA(recaptchaRef);
		await setBacdrop(false);

		setName('');
		setEmail('');
		setPhoneNumber('');
		setMessage('');

		setTimeout(() => {
			setShowFormSentSuccess(false);
			setShowFormSentFailure(false);
		}, 6000);
	};

	useEffect(() => {
		const inputsfilled = !(!name || !email || !phoneNumber || !message);
		const freeOfErrors = !errors.nameError && !errors.emailError && !errors.phoneNumberError && !errors.messageError;

		if (inputsfilled && freeOfErrors && wifiStatus) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [backdrop, email, errors.emailError, errors.messageError, errors.nameError, errors.phoneNumberError, message, name, phoneNumber, wifiStatus]);

	useEffect(() => {
		if (!wifiStatus) {
			setBacdrop(false);
			setShowWifiFailure(true);
		} else {
			setShowWifiFailure(false);
		}
	}, [wifiStatus]);

	return (
		<Grid
			container
			spacing={3}
			justifyContent={'center'}
			className={[classes.root, props.className].join(' ')}
			style={props.style}
		>
			<Backdrop open={backdrop} className={classes.backdrop}>
				<CircularProgress color={'primary'} />
			</Backdrop>
			<Subtitle
				text={ti18n(appContent.ContactForm, content.title)}
				color={theme.palette.common.white}
			/>

			{/* Name */}
			<Grid item xs={12}>
				<TextField
					id={'name'}
					className={classes.textField}
					autoComplete={'name'}
					error={Boolean(errors.nameError)}
					helperText={errors.nameError}
					fullWidth
					label={ti18n(appContent.ContactForm, content.inputFields.name.label)}
					placeholder={ti18n(appContent.ContactForm, content.inputFields.name.placeholder)}
					type={'name'}
					variant={'outlined'}
					onChange={(event): void => nameValidation(event.target.value)}
					value={name}
					onBlur={(event): void => nameValidation(event.target.value.trim())}
					required
				/>
			</Grid>

			{/* E-mail */}
			<Grid item xs={12}>
				<TextField
					id={'email'}
					className={classes.textField}
					autoComplete={'email'}
					error={Boolean(errors.emailError)}
					helperText={errors.emailError}
					fullWidth
					label={ti18n(appContent.ContactForm, content.inputFields.email.label)}
					placeholder={ti18n(appContent.ContactForm, content.inputFields.email.placeholder)}
					type={'email'}
					variant={'outlined'}
					onChange={(event): void => emailValidation(event.target.value)}
					value={email}
					onBlur={(event): void => emailValidation(event.target.value)}
					required
				/>
			</Grid>

			{/* Phone number */}
			<Grid item xs={12}>
				<TextField
					id={'phone'}
					className={classes.textField}
					autoComplete={'phone'}
					error={Boolean(errors.phoneNumberError)}
					helperText={errors.phoneNumberError}
					fullWidth
					label={ti18n(appContent.ContactForm, content.inputFields.phoneNumber.label)}
					placeholder={ti18n(appContent.ContactForm, content.inputFields.phoneNumber.placeholder)}
					type={'phone'}
					variant={'outlined'}
					onChange={(event): void => phoneNumberValidation(event.target.value)}
					value={phoneNumber}
					onBlur={(event): void => phoneNumberValidation(event.target.value.trim())}
					required
				/>
			</Grid>

			{/* Message */}
			<Grid item xs={12}>
				<TextField
					id={'Melding'}
					className={classes.textField}
					error={Boolean(errors.messageError)}
					helperText={
						errors.messageError
							? errors.messageError
							: messageCounter + ti18n(appContent.ContactForm, content.inputFields.message.charLeft)
					}
					fullWidth
					label={ti18n(appContent.ContactForm, content.inputFields.message.label)}
					placeholder={ti18n(appContent.ContactForm, content.inputFields.message.placeholder)}
					multiline
					rows={4}
					variant={'outlined'}
					onChange={(event): void => {
						setMessageCounter(messageCharacterLimit - event.target.value.length);
						messageValidation(event.target.value);
					}}
					value={message}
					onBlur={(event): void => messageValidation(event.target.value)}
					inputProps={{ maxLength: messageCharacterLimit }}
					required
				/>
			</Grid>

			<Grid item xs={12}>
				{/* <ReCAPTCHA
					ref={recaptchaRef}
					size={'invisible'}
					sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
				/> */}

				<SecondaryButton
					text={ti18n(appContent.ContactForm, content.sendBtn)}
					animate
					onClick={(event): Promise<void> => sendForm(event)}
					disable={disabled}
				/>
			</Grid>
			{
				showFormSentSuccess
					? <Alert className={classes.alert} severity={'success'}>
						<AlertTitle>{ti18n(appContent.ContactForm, content.formMsgSent.success.title)}</AlertTitle>
						{ti18n(appContent.ContactForm, content.formMsgSent.success.body)}
					</Alert>
					: null
			}
			{
				showFormSentFailure
					? <Alert className={classes.alert} severity={'error'}>
						<AlertTitle>{ti18n(appContent.ContactForm, content.formMsgSent.failure.title)}</AlertTitle>
						{ti18n(appContent.ContactForm, content.formMsgSent.failure.body)}
					</Alert>
					: null
			}
			{
				showWifiFailure
					? <Alert className={classes.alert} severity={'error'}>
						<Typography style={{ fontWeight: 'bold' }}>{ti18n(appContent.ContactForm, content.formMsgSent.network.title)}</Typography>
					</Alert>
					: null
			}
			<Grid item xs={12}>
				<Divider variant={'middle'} />
			</Grid>
			<Grid item xs={12} style={{ display: 'grid', justifyContent: 'center' }}>
				<SecondaryButton
					icon={<PhoneIcon color={'primary'} />}
					text={globalContent.phoneNumber}
					href={'tel:' + globalContent.phoneNumber}
					animate
					className={classes.contact}
				/>
				<SecondaryButton
					icon={<EmailIcon color={'primary'} />}
					text={globalContent.email}
					href={'mailto:' + globalContent.email}
					animate
					className={classes.contact}
				/>
			</Grid>
		</Grid>
	);
};

export default ContactForm;
