import ArabLeagueFlag from '../../static/images/Flags/arab-league-flag-icon-64.png';
import NorwegianFlag from '../../static/images/Flags/norway-flag-icon-64.png';
import PolandFlag from '../../static/images/Flags/poland-flag-icon-64.png';
import UKFlag from '../../static/images/Flags/united-kingdom-flag-icon-64.png';
import i18next from 'i18next';

export interface languageType {
	language: string;
	languageCode: string;
	flagPath: string;
}

export let defaultLanguage = 	{
	language: 'Norwegian',
	languageCode: 'no',
	flagPath: NorwegianFlag
};

export const appLanguages: languageType[] = [
	{
		language: 'Norwegian',
		languageCode: 'no',
		flagPath: NorwegianFlag
	},
	{
		language: 'English',
		languageCode: 'en',
		flagPath: UKFlag
	},
	{
		language: 'Polish',
		languageCode: 'pl',
		flagPath: PolandFlag
	},
	{
		language: 'Arabic',
		languageCode: 'ar',
		flagPath: ArabLeagueFlag
	},
];

export const changeLanguage = (newLanguageCode: string): void => {
	defaultLanguage = appLanguages.filter(languageItem => languageItem.languageCode === newLanguageCode)[0];

	i18next.changeLanguage(defaultLanguage.languageCode);
};
