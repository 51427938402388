import { Button, Grid, Typography } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';

import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import BusinessIcon from '@material-ui/icons/Business';
import CopyrightIcon from '@material-ui/icons/Copyright';
import CustomHeaderSection from '../Sections/CustomHeaderSection/CustomHeaderSection';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import React from 'react';
import Subtitle from '../Typography/Subtitle';
import { appContent } from '../../service/localization/application.i18n';
import { footerStyles } from './Footer.styles';
import globalContent from '../global/global.content.json';
import logo from '../../static/images/Viken.png';
import { menuListType } from '../NavBar/NavBarMenu.types';
import { subMenuList } from '../NavBar/NavBarMenuItems';
import { theme } from '../global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useColumnDivider } from '../../service/service/useColumnDivider';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
	useTranslation();
	const content = appContent.Footer.content.no;
	const classes = footerStyles();
	const history = useHistory();

	return (
		<CustomHeaderSection
			className={classes.root}
			style={{ backgroundColor: theme.palette.common.black }}
		>
			<Grid
				container
				spacing={3}
				className={[classes.footerGridMobile, classes.footerText].join(' ')}
			>
				<Grid item xs={useColumnDivider(4, 12)}>
					<Button
						onClick={(): void => history.push(APP_ROUTES.ROOT)}
						onKeyPress={(): void => history.push(APP_ROUTES.ROOT)}
					>
						<img src={logo} alt={'Fotter Logo'} className={classes.logo} />
					</Button>
				</Grid>
				<Grid item xs={useColumnDivider(4, 12)} className={classes.rettsOmrader}>
					<Subtitle text={ti18n(appContent.Footer, content.rettsområder)} />
					{
						subMenuList.map((menuItem: menuListType) =>
							<NavLink
								key={menuItem.link}
								to={menuItem.link}
								className={[classes.footerText, classes.link].join(' ')}
								activeStyle={{
									borderBottom: '2px solid',
									borderRadius: '5px'
								}}
							>
								<Typography>
									{ti18n(appContent.NavBar, menuItem.title)}
								</Typography>
							</NavLink>
						)
					}
				</Grid>
				<Grid item xs={useColumnDivider(4, 12)}>
					<Subtitle text={ti18n(appContent.Footer, content.contactUs.contactUsTitle)} />
					<div className={classes.withIcon}>
						<BusinessIcon color={'primary'} className={classes.icon}/>
						<Typography style={{ fontWeight: 'bold' }}>{ti18n(appContent.Footer, content.contactUs.adressTitle)}</Typography>
					</div>
					<address>
						<Typography>{globalContent.adress.adressName}</Typography>
						<Typography>{globalContent.adress.postCodeArea}</Typography>
						<br />
						<a className={[classes.footerText, classes.link].join(' ')} href={'tel:' + globalContent.phoneNumber}>
							<div className={classes.withIcon}>
								<PhoneIcon color={'primary'} className={classes.icon}/>
								{globalContent.phoneNumber}
							</div>
						</a>
						<br />
						<a className={[classes.footerText, classes.link].join(' ')} href={'mailto:' + globalContent.email}>
							<div className={classes.withIcon}>
								<EmailIcon color={'primary'} className={classes.icon}/>
								{globalContent.email}
							</div>
						</a>
					</address>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					{/* <NavLink
						to={APP_ROUTES.PERSONVERN}
						className={[classes.footerText, classes.link].join(' ')}
						activeStyle={{
							borderBottom: '2px solid',
							borderRadius: '5px'
						}}
					>
						<Typography>
							{ti18n(appContent.Footer, content.privacy)}
						</Typography>
					</NavLink>
					<br /> */}
					<div className={classes.withIcon}>
						<CopyrightIcon color={'primary'} className={classes.icon}/>
						{ti18n(appContent.Footer, content.copyright)}
					</div>
				</Grid>
			</Grid>
		</CustomHeaderSection>
	);
};

export default Footer;
