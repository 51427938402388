import { useEffect, useRef } from 'react';

export const usePrevious = (value: any): any => {
	const previousRef = useRef();

	useEffect(() => {
		previousRef.current = value;
	});

	return previousRef.current;
};
