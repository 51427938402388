import i18next from 'i18next';

const compareArrays = (array1: any[], array2: any[]): boolean => {
	const isSame = array1.length === array2.length && array1.every((element, index) => {
		return element === array2[index];
	});

	return isSame;
};

const typeOfArray = (arrayX: any[]): boolean => {
	return arrayX.every(i => {

		return typeof i === 'string';
	});
};

const findPathOfObjectValue = (obj: any, item: any, level: number, path: string[]): any => {
	let newPath: string[] = path;

	for (const key in obj) {
		if (obj[key] !== null && Array.isArray(obj[key])) {
			const isArrayOfStrings = typeOfArray(obj[key]);
			const arraysAreEqual = compareArrays(obj[key], item);

			if (isArrayOfStrings && arraysAreEqual) {
				newPath.push(key);
				break;
			}

		} else if (typeof obj[key] === 'object' && obj[key] !== null) {
			newPath.push(key);
			const copiedNewPath = [...newPath];
			const recursivedReturnedNewPath = findPathOfObjectValue(obj[key], item, level + 1, copiedNewPath);

			if (compareArrays(recursivedReturnedNewPath, newPath)) {
				const index = newPath.indexOf(key);

				if (index > -1) {
					newPath.splice(index, 1);
				}
			} else {
				newPath = [...recursivedReturnedNewPath];

				return newPath;
			}
		} else if (obj[key] === item) {
			newPath.push(key);
			break;
		}
	}

	return newPath;
};

export const ti18n = (resource: any, txt: string | string[]): any | any[] => {
	const pathArray = findPathOfObjectValue(resource.content.no, txt, 0, []);

	const result = `${resource.name}:${pathArray.join('.')}`;

	return i18next.t(result, { returnObjects: true });
};
