import ContactForm from '../../components/ContactForm/ContactForm';
import CustomHeaderSection from '../../components/Sections/CustomHeaderSection/CustomHeaderSection';
import { Grid } from '@material-ui/core';
import Paragraph from '../../components/Typography/Paragraph';
import React from 'react';
import Section from '../../components/Sections/Section/Section';
import Subtitle from '../../components/Typography/Subtitle';
import Title from '../../components/Typography/Title';
// import { arbeidsrettStyles } from './Arbeidsrett.styles';
import { appContent } from '../../service/localization/application.i18n';
import { theme } from '../../components/global/global.styles';
import { ti18n } from '../../service/localization/ti18n';
import { useColumnDivider } from '../../service/service/useColumnDivider';
import { useTranslation } from 'react-i18next';

const Arbeidsrett: React.FC = () => {
	// const classes = arbeidsrettStyles();
	useTranslation();
	const content = appContent.Arbeidsrett.content.no;

	return (
		<React.Fragment>
			<CustomHeaderSection>
				<Title
					text={ti18n(appContent.Arbeidsrett, content.title)}
					color={theme.palette.common.white}
					bold
				/>

				<Subtitle
					text={ti18n(appContent.Arbeidsrett, content.subTitle)}
					color={theme.palette.common.white}
				/>
			</CustomHeaderSection>
			<Section>
				<Grid container spacing={3}>
					<Grid item xs={useColumnDivider(6, 12)}>
						<Paragraph
							title={ti18n(appContent.Arbeidsrett, content.oppsigelseAvskjed.title)}
							body={ti18n(appContent.Arbeidsrett, ti18n(appContent.Arbeidsrett, content.oppsigelseAvskjed.body))}
						/>
						<Paragraph
							title={ti18n(appContent.Arbeidsrett, content.fagområder.title)}
							list={ti18n(appContent.Arbeidsrett, content.fagområder.list)}

							// list={t('Arbeidsrett:fagområder.list', { returnObjects: true })}
						/>
					</Grid>
					<Grid item xs={useColumnDivider(6, 12)}>
						<ContactForm pathName={ti18n(appContent.Arbeidsrett, content.title)} />
					</Grid>
				</Grid>
			</Section>
		</React.Fragment>
	);
};

export default Arbeidsrett;
