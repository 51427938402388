import { Collapse, Divider, List, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';

import CustomMobileNavBarItem from './CustomMobileNavBarItem';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { navBarItemType } from '../NavBarMenu.types';

const MobileNavBarItem: React.FC<navBarItemType> = (props: navBarItemType) => {
	const isExpandable = props.items && props.items.length > 0;
	const [open, setOpen] = useState(false);

	const handleClick = (): void => {
		setOpen(!open);
	};

	const MenuItemRoot = <React.Fragment>
		<CustomMobileNavBarItem {...props} onClick={handleClick}>
			<ListItemText primary={props.title} inset={props.items.length === 0} />
			{/* Display the expand menu if the item has children */}
			{isExpandable && !open && <IconExpandMore />}
			{isExpandable && open && <IconExpandLess />}
		</CustomMobileNavBarItem>
		<Divider variant={'middle'} />
	</React.Fragment>;

	const MenuItemChildren = isExpandable
		? <Collapse in={open} timeout={'auto'} unmountOnExit>
			<List component={'nav'} disablePadding>
				{props.items.map((item, index) =>
					<MobileNavBarItem {...item} key={index} />
				)}
			</List>
		</Collapse>
		: null;

	return (
		<React.Fragment>
			{MenuItemRoot}
			{MenuItemChildren}
		</React.Fragment>
	);
};

export default MobileNavBarItem;
