import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../global/global.styles';

const animation = (props: any): "scale(1.1)" | null => {
	return props.animate ? 'scale(1.1)' : null;
};

export const customListStyles = makeStyles((theme: Theme) => createStyles({
	list: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: globalStyles.borderRadius.large20px,
	},
	listElement: {
		borderRadius: globalStyles.borderRadius.large20px,
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.standard,
		}),
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transform: (props): string | null => animation(props)
		}
	},
	listContent: {
		color: theme.palette.common.white,
	},
}));
