import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../global/global.styles';

export const sectionStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		paddingRight: globalStyles.appMargin,
		paddingLeft: globalStyles.appMargin,
		[theme.breakpoints.down('sm')]: {
			paddingRight: globalStyles.appMarginMobile,
			paddingLeft: globalStyles.appMarginMobile,
		},
	},
	customSectionStyle: {
		paddingBottom: '5%',
		marginBottom: '5%',
	},
	customHeaderSectionStyle: {
		display: 'block',
		padding: '5% 16%',
	},
}));
