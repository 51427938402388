import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../../components/global/global.styles';

export const tilbyrStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		display: 'block',
	},
	icon: {
		color: theme.palette.common.white,
		fontSize: 50,
		padding: '5%'
	},
	gridButtons: {
		marginTop: '5%',
		[theme.breakpoints.down('sm')]: {
			paddingRight: globalStyles.appMarginMobile,
			paddingLeft: globalStyles.appMarginMobile,
		},
		[theme.breakpoints.down('xs')]: {
			display: 'block',
		},
	},
}));
