import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { globalStyles } from '../global/global.styles';

export const navBarStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
		},
	},
	section: {
		position: 'sticky',
		top: '0',
		zIndex: 1,
		[theme.breakpoints.down('sm')]: {
			padding: '0%',
		},
	},
	logo: {
		width: '100%',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			width: '125%',
		},
		'&:hover': {
			filter: 'brightness(50%)',
		},
	},
	languageDropDown: {
		width: '75px',
	},
	flagIcons: {
		width: '32px',
		height: '25px',
		borderRadius: '5px',
		border: '1px solid ' + theme.palette.common.black,
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.standard,
		}),
		'&:hover': {
			transform: 'scale(1.1)',
		},
	},
	desktop: {
		width: '100%',
		display: 'inherit',
		justifyContent: 'space-around',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	mobile: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			width: '100%',
			marginBottom: '10px',
		},
	},
	mobileNavBar: {
		width: '100%',
		backgroundColor: theme.palette.primary.main,
		borderRadius: globalStyles.borderRadius.large20px,
	},
	menuItem: {
		color: theme.palette.primary.dark,
		'&:hover': {
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			color: theme.palette.common.white,
		},
	},
	link: {
		textDecoration: 'none',
		cursor: 'pointer',
		padding: '5px',
		'&:hover': {
			textDecoration: 'none',
			color: theme.palette.common.white,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
		},
	},
}));
