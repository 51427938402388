import { List, ListItem, MenuItem, Popover } from '@material-ui/core';
import { appLanguages, changeLanguage, defaultLanguage } from '../../service/localization/languages';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { navBarStyles } from './NavBar.styles';
import { theme } from '../global/global.styles';

const LanguageDropDown: React.FC = () => {
	const classes = navBarStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleAnchorClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleAnchorClose = (): void => {
		setAnchorEl(null);
	};

	const handeLanguageChange = (newLanguage: string): void => {
		changeLanguage(newLanguage);
		handleAnchorClose();
	};

	return (
		<div className={classes.languageDropDown}>
			<MenuItem role={'button'} style={{ color: theme.palette.primary.dark }} onClick={handleAnchorClick}>
				<img src={defaultLanguage.flagPath} alt={defaultLanguage.language} className={classes.flagIcons} />
				<ArrowDropDownIcon />
			</MenuItem>
			<Popover
				id={'språk-menu'}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={Boolean(anchorEl)}
				onClose={handleAnchorClose}
			>
				<List component={'nav'} aria-label={'main mailbox folders'}>
					{
						appLanguages.map(language =>
							<ListItem key={language.languageCode} button onClick={(): void => handeLanguageChange(language.languageCode)}>
								<img src={language.flagPath} alt={language.language} className={classes.flagIcons} />
							</ListItem>
						)
					}
				</List>
			</Popover>
		</div>
	);
};

export default LanguageDropDown;
