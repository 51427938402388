import CustomTypography from './CustonTypography';
import React from 'react';
import { customTypographyType } from './Typograhy.types';
import { globalStyles } from '../global/global.styles';

const Subtitle: React.FC<customTypographyType> = (props: customTypographyType) => {
	return (
		<CustomTypography
			variant={globalStyles.typography.subTitle.size}
			className={[props.className].join(' ')}
			style={{
				marginBottom: globalStyles.typography.subTitle.margin12px,
				...props.style,
			}}
			{...props}
		/>
	);
};

export default Subtitle;
