import { ClickAwayListener, Collapse, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import ClearIcon from '@material-ui/icons/Clear';
import LanguageDropDown from '../LanguageDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import MobileNavBarItem from './MobileNavBarItem';
import { appContent } from '../../../service/localization/application.i18n';
import { menuList } from '../NavBarMenuItems';
import { menuListType } from '../NavBarMenu.types';
import { navBarStyles } from '../NavBar.styles';
import { ti18n } from '../../../service/localization/ti18n';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '../../../service/service/usePrevious';

const MobileNavBar: React.FC = () => {
	const classes = navBarStyles();
	const content = appContent.NavBar.content.no;
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const previousLocation = usePrevious(location);

	useEffect(() => {
		if (previousLocation) {
			if (previousLocation !== location) {
				setOpen(false);
			}
		}
	}, [location, previousLocation]);

	const handleClick = (): void => {
		setOpen(!open);
	};

	return (
		<ClickAwayListener onClickAway={(): void => setOpen(false)}>
			<div className={classes.mobile}>
				<List component={'nav'} disablePadding className={classes.mobileNavBar}>
					<ListItem button onClick={handleClick}>
						{open ? <ClearIcon /> : <MenuIcon />}
						<ListItemText primary={ti18n(appContent.NavBar, content.menu)} />
					</ListItem>

					<Collapse in={open} timeout={'auto'} unmountOnExit>
						<Divider />
						<List component={'div'} disablePadding>
							<MobileNavBarItem items={[]} link={APP_ROUTES.ROOT} title={ti18n(appContent.NavBar, content.navBarLinks.home)} />
							{/* <Divider variant={'middle'} /> */}
							{
								menuList.map((item: menuListType, index: number) =>
									<MobileNavBarItem {...item} key={index} />
								)
							}
						</List>
					</Collapse>
				</List>
				<LanguageDropDown />
			</div>
		</ClickAwayListener>
	);
};

export default MobileNavBar;
