import CustomNavLink from '../CustomNavLink';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { navBarItemType } from '../NavBarMenu.types';

const CustomMobileNavBarItem: React.FC<navBarItemType> = (props: navBarItemType) => {
	// If link is not set return the orinary ListItem
	if (!props.link || typeof props.link !== 'string') {
		return (
			<ListItem button {...props}>
				{props.children}
			</ListItem>
		);
	}

	return (
		<CustomNavLink {...props} key={props.link} />
	);
};

export default CustomMobileNavBarItem;
